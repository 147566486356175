import React from "react"
import VerticallyCenteredRow from "./verticallyCenteredRow.js"

export default function MediaSelector(props) {
  const scrollToMenu = () => {
    let workElement = document.getElementById("work1")
    workElement.scrollIntoView({
      behavior: "smooth",
      block: "start",
    })
  }

  return (
    <VerticallyCenteredRow style={styles.mediaSelection}>
      <MediaTypeTitle mediaType="all" title="All" {...props} />
      <MediaTypeTitle mediaType="apps" title="Mobile" {...props} />
      <MediaTypeTitle mediaType="web" title="Web" {...props} />
      <MediaTypeTitle mediaType="vr" title="VR" {...props} />
    </VerticallyCenteredRow>
  )

  function MediaTypeTitle(props) {
    return (
      <h3
        onClick={() => {
          scrollToMenu()
          props.onChangeMedia(props.mediaType)
        }}
        className="mediaSelectionButton"
        style={{
          ...styles.noMarginTop,
          ...(props.currentMedia === props.mediaType ? styles.selected : {}),
        }}
      >
        {props.title}
      </h3>
    )
  }
}

const styles = {
  noMarginTop: {
    marginTop: 0,
  },
  mediaSelection: {
    width: 300,
    justifyContent: "space-between",
    margin: "50px auto -40px auto",
  },
  selected: {
    textDecoration: "underline",
  },
}
