import MemoryViewsImage from "../components/memoryViewsImage"
import RotoImage from "../components/rotohiveImage"
import DribeImage from "../components/dribeImage"
import RLImage from "../components/rocketLeagueImage"
import RoverImage from "../components/roverImage"
import Cram from "../components/cramImage"
import PTPlusImage from "../components/ptplusImage"
import FYCItImage from "../components/FYCItImage"
import SmartKanban from "../components/smartKanbanImage"
import WodageImage from "../components/wodageImage"

export default [
  {
    title: "FYCIt",
    description:
      "FYCit is the only app for awards voters and guild members to find awards screenings and events happening in Los Anglees, New York, and virtually online. Mobile app built with Flutter with a web-based admin interface built with React.",
    imageComponent: FYCItImage,
    category: ["web", "apps"],
    link: "https://fycit.app"
  },
  {
    title: "Smart Kanban",
    description:
      "Smart Kanban is a workflow management tool built on top of the ethereum blockchain. Smart Kanban was created as a proof of concept research project for Toyota Motor North America along with 7 other UTD students. I was the lead front-end engineer on the project using React, TypeScript, Styled Components, and Redux. People use Smart Kanban to facilitate their work environment and orchestrate their processes.",
    imageComponent: SmartKanban,
    category: ["web"],
  },
  {
    title: "Wodage",
    description:
      "Workout Performance Analysis -- Wodage tracks, analyzes, and provides crucial data on your fitness performance. By using state-of-the-art data analysis Wodage provides you the information you need to take your fitness to the next level.",
    imageComponent: WodageImage,
    category: ["apps"],
    link: "https://apps.apple.com/us/app/wodage/id1437080728",
  },
  {
    title: "PT Plus",
    description:
      "PT Plus is a a virtual-reality physical therapy application for patients with decreased range of motion in the neck and surrounding area. The application was built on Unity, SteamVR, and Google Firebase.",
    imageComponent: PTPlusImage,
    category: ["apps", "vr"],
  },
  {
    title: "MemoryViews",
    description:
      "The MemoryView app enables family members to communicate with their loved ones through the pictures they take every day of themselves. Pictures are uploaded to the system with an easy to use smartphone application. The photos can then be viewed on a MemoryViews enabled smart TV.",
    imageComponent: MemoryViewsImage,
    category: ["apps"],
    link: "https://www.memoryviews.com/",
  },
  {
    title: "RotoHive",
    description:
      "RotoHive is a new type of weekly fantasy sports tournament that runs on the Ethereum blockchain. RotoHive users are asked to submit their fantasy player rankings for a slate of games and then compared against live results. I was responsible for constructing the entirety of the front-end website, integrating with the ethereum blockchain, designing user interfaces and user experiences, and managing the database and authentication layer of the application over the summer of 2018.",
    imageComponent: RotoImage,
    category: ["web"],
  },
  {
    title: "Dribe",
    description:
      "Dribe is a revolutionary new way to drive a car in Denmark. Instead of leasing or buying, have the freedom to change your car whenever you want. I worked on the Dribe mobile app for three months at Mutual Mobile over the summer of 2017. I was responsible for implementing front-end designs in react native, adding the authentication and authorization layer, building a computer vision system to scan and validate foreign driver's licenses, and integrating verification services with international government systems.",
    imageComponent: DribeImage,
    category: ["apps"],
    link: "https://dribe.dk/",
  }
  // {
  //   title: "Cram",
  //   description:
  //     "Cram is a speed-reading application controlled through the use of facial expressions. By tracking over 40 individual points on the readers face, Cram can analyze your appearance in real time. When you smile, the speed viewer displays words faster. When you raise your eyebrows, it slows down. Looking away from the screen will automatically pause what you are reading. Tilting your head left or right skips forward or backward in the text respectively. Cram was created at HackTX 2018 and ultimately won first prize out of 700 hackers.",
  //   imageComponent: Cram,
  //   category: ["web"],
  //   link: "https://devpost.com/software/cram-56c1re",
  // },
  // {
  //   title: "Rocket League Exchange",
  //   description:
  //     "Rocket League Exchange is a reddit integrated trading site for the Game of the Year of 2015. It allows users to browse the newest trade offers on reddit or search for specific items by console.",
  //   imageComponent: RLImage,
  //   category: ["web"],
  //   link: "https://rocketleagueexchange.herokuapp.com/",
  // },
  // {
  //   title: "Rovr",
  //   description:
  //     "Rovr is a website that allows users to look at photos from the martian rover Curiosity by earth date. Easily discover what photos were taken on your birthday, on independence day, or any other day of the year.",
  //   imageComponent: RoverImage,
  //   category: ["web"]
  // }
]
