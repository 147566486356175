import React from "react"
import PortfolioProject from "./portfolioProject"
import projects from "../data/projects"

export class ProjectList extends React.Component {
  render() {
    return (
      <div
        style={{
          minHeight: 500,
          width: "100%",
          overflow: "hidden",
        }}
      >
        {projects
          .filter(
            project =>
              project.category.includes(this.props.media) ||
              this.props.media === "all"
          )
          .map((project, index) => (
            <PortfolioProject
              key={index}
              trails={this.props.trails}
              index={index}
              {...project}
            />
          ))}
      </div>
    )
  }
}
