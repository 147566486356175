import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import FadeIn from "../components/fadeIn"
import { IndexHeader } from "../components/indexHeader"
import MediaSelector from "../components/mediaSelector"
import projects from "../data/projects"
import ShapeParticles from "../components/ShapeParticles"
import { useTrail } from "react-spring"
import { SocialIconRow } from "../components/SocialIconRow"
import { HireMeButtons } from "../components/HireMeButtons"
import { ProjectList } from "../components/ProjectList"

const IndexPage = () => {
  const [media, setMedia] = useState("all")
  const trails = useTrail(projects.length * 3, {
    opacity: 1,
    config: { mass: 3, tension: 600, friction: 50 },
    to: { transform: `translateX( 0px )`, opacity: 1 },
    from: { transform: `translateX( 100px )`, opacity: 0 },
    reset: true,
  })

  return (
    <div>
      <ShapeParticles />
      <Layout fixedFooter>
        <SEO title="Home" keywords={["Liam", "McMains", "Liam McMains"]} />

        <FadeIn>
          <IndexHeader />
          <SocialIconRow />
          <HireMeButtons />

          <HorizontalRules />

          <MediaSelector currentMedia={media} onChangeMedia={setMedia} />
          <ProjectList media={media} trails={trails} />
        </FadeIn>
      </Layout>
    </div>
  )
}

const HorizontalRules = () => (
  <div>
    <hr id="work1" />
  </div>
)

export const styles = {
  jumobtronStyles: {
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
}

export default IndexPage
