import React from "react"
import { SocialIcon } from "react-social-icons"
import VerticallyCenteredRow from "./verticallyCenteredRow"

export class SocialIconRow extends React.Component {
  render() {
    return (
      <VerticallyCenteredRow style={styles.socialIcons}>
        <SocialIcon url="https://www.linkedin.com/in/liammcmains/" />
        {/* <SocialIcon url="https://medium.com/@liam.john.mcmains" /> */}
        <SocialIcon url="https://stackoverflow.com/users/4592747/spiritman110" />
        <SocialIcon url="https://github.com/McMainsLiam" />
        <SocialIcon url="mailto::liam@mcmains.net" />
      </VerticallyCenteredRow>
    )
  }
}

const styles = {
  socialIcons: {
    width: "100%",
    maxWidth: 300,
    marginTop: 15,
    justifyContent: "space-between",
  },
}
