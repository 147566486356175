import React from "react"
import { animated } from "react-spring"

const PortfolioProject = props => {
  return (
    <div>
      <animated.div
        className="imageContainer"
        style={{ ...styles.imageContainer, ...props.trails[props.index * 3] }}
      >
        <props.imageComponent />
      </animated.div>
      <animated.h1
        className="projectText"
        style={{ ...styles.title, ...props.trails[props.index * 3 + 1] }}
      >
        {props.link && (
          <a target="_blank" rel="noopener noreferrer" href={props.link}>
            {props.title}
          </a>
        )}

        {!props.link && props.title}
      </animated.h1>
      <animated.p
        className="projectText"
        style={{ ...styles.content, ...props.trails[props.index * 3 + 2] }}
      >
        {props.description}
      </animated.p>
    </div>
  )
}

const styles = {
  imageContainer: {
    borderRadius: 20,
    maxWidth: "80%",
    maxHeight: "80%",
    width: "auto",
    height: "auto",
    overflow: "hidden",
    margin: "60px auto 1vw auto",
    boxShadow: `0px 0px 1.5vw rgba(0,0,0,.3)`,
  },
  title: {
    margin: "2vw 10% 1vw 10%",
    backgroundColor: 'transparent'
  },
  content: {
    margin: "30px 10%",
    fontSize: 16,
    marginBottom: "70px",
  },
}

export default PortfolioProject
