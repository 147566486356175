import React from "react"

export class IndexHeader extends React.Component {
  render() {
    return (
      <div>
        <h2 style={styles.noMarginBottom}>Hello, I'm</h2>
        <h1
          style={{
            ...styles.noMarginTop,
            ...styles.noMarginBottom,
          }}
        >
          Liam McMains
        </h1>
        <h2 style={styles.noMarginTopSubtitle}>Software Engineer</h2>
      </div>
    )
  }
}

const styles = {
  noMarginTop: {
    marginTop: 10,
  },
  noMarginBottom: {
    marginBottom: 10,
  },
  noMarginTopSubtitle: {
    marginTop: 0,
    color: "#555",
  },
}
